import axios from "axios";
import { gettoken } from "../../../../shared/services/token/token";
import apiurl from "../apiendpoint/apiendpoint";

export const apisaveGallery = async (formdata) => {
    try {
        const token = gettoken();
        const res = await axios.post(`${apiurl()}/gallery/apisaveGallery`, formdata, { 
            headers: { 
                "Authorization": `Bearer ${token}`,
                "Content-Type": "multipart/form-data"
            }
        });
        return res.data;
    } catch (err) {
        console.error("Gallery save error:", err);
        throw new Error(err.response?.data?.message || "Failed to save gallery item");
    }
};

export const apiupdateGallery = async (formdata) => {
    try {
        const token = gettoken();
        const res = await axios.put(
            `${apiurl()}/gallery/apiupdateGallery/${formdata.get('_id')}`,
            formdata,
            {
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return res.data;
    } catch (err) {
        console.error(err);
        throw err; 
    }
};

export const apideleteGallery = async (_id) => {
    try {
        const token = gettoken();
        const res = await axios.delete(`${apiurl()}/gallery/apideleteGallery/${_id}`,{headers: { "Authorization": `Bearer ${token}` }});
        return res.data;
    } catch (err) {
        console.log(err);
    }
};

export const apigetallGallery = async (params) => {
    try {
        const token = gettoken();
        const res = await axios.get(`${apiurl()}/gallery/apigetGallery`,{params:params,headers: { "Authorization": `Bearer ${token}` }});
        return res.data;
    } catch (err) {
        console.log(err);
    }
};