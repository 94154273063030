/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { apigetallstuddashcourse } from "../../../services/apistudentdashboard/apistudentDash";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import apiurl from "../../../services/apiendpoint/apiendpoint";
import { gettoken } from "../../../../../shared/services/token/token";
import axios from "axios";

export default function CourseDetails() {
  const { courseId } = useParams();
  const [courses, setCourses] = useState([]);
  const [activeTab, setActiveTab] = useState("pdf");
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [embedUrl, setEmbedUrl] = useState("");
  const [pdfUrls, setPdfUrls] = useState([]);
  const [currentPdfIndex, setCurrentPdfIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    toolbarPlugin: {
      zoomPlugin: true,
      pageNavigationPlugin: true,
    },
    renderToolbar: (Toolbar) => {},
  });

  useEffect(() => {
    const disableTextSelection = () => {
      document.addEventListener("selectstart", (e) => e.preventDefault());
    };

    const disableRightClick = () => {
      document.addEventListener("contextmenu", (e) => e.preventDefault());
    };

    const disableCopyPaste = (e) => {
      if (e.ctrlKey && (e.key === "c" || e.key === "p")) {
        e.preventDefault();
      }
    };

    disableTextSelection();
    disableRightClick();
    document.addEventListener("keydown", disableCopyPaste);

    return () => {
      document.removeEventListener("selectstart", (e) => e.preventDefault());
      document.removeEventListener("contextmenu", (e) => e.preventDefault());
      document.removeEventListener("keydown", disableCopyPaste);
    };
  }, []);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const params = {};
        const data = await apigetallstuddashcourse(params);
        setCourses(data.coursesData);
        const course = data.coursesData.find(
          (course) => course._id === courseId
        );
        setSelectedCourse(course);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    fetchCourses();
  }, [courseId]);

  useEffect(() => {
    if (selectedCourse && selectedCourse.Course_Video) {
      const youtubeId = extractYouTubeId(selectedCourse.Course_Video);
      if (youtubeId) {
        setEmbedUrl(`https://www.youtube.com/embed/${youtubeId}`);
      } else {
        setEmbedUrl(selectedCourse.Course_Video);
      }
    }
  }, [selectedCourse]);

  useEffect(() => {
    const fetchPdfsWithToken = async () => {
      try {
        setLoading(true);
        setError(null);
        setCurrentPdfIndex(0);
        setPdfUrls([]);

        const token = gettoken();
        const pdfPromises = selectedCourse.Course_Pdf.map(async (pdfPath, index) => {
          try {
            const response = await axios.get(
              `${apiurl()}/stream/apigetcoursepdf?_id=${selectedCourse._id}&pdfIndex=${index}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
                responseType: "blob",
              }
            );

            return URL.createObjectURL(response.data);
          } catch (error) {
            console.error(`Error fetching PDF at index ${index}:`, error);
            return null;
          }
        });

        const resolvedPdfUrls = await Promise.all(pdfPromises);
        setPdfUrls(resolvedPdfUrls.filter(url => url !== null));
      } catch (error) {
        setError("Failed to load PDFs. Please try again.");
        console.error("Error fetching PDFs:", error);
      } finally {
        setLoading(false);
      }
    };

    if (selectedCourse && selectedCourse._id && selectedCourse.Course_Pdf?.length) {
      fetchPdfsWithToken();
    }
  }, [selectedCourse]);

  const extractYouTubeId = (url) => {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  };

  const handleNextPdf = () => {
    setCurrentPdfIndex((prevIndex) => 
      (prevIndex + 1) % pdfUrls.length
    );
  };

  const handlePrevPdf = () => {
    setCurrentPdfIndex((prevIndex) => 
      prevIndex === 0 ? pdfUrls.length - 1 : prevIndex - 1
    );
  };

  if (!selectedCourse) return <div>Loading...</div>;

  return (
    <div className="px-4 py-8">
      <h2 className="mb-6 text-2xl font-bold">{selectedCourse.Course_Title}</h2>
      <div className="mb-4 space-x-5">
        <button
          className={`inline-flex items-center justify-center px-6 py-3 text-xl font-bold leading-6 text-center ${
            activeTab === "pdf"
              ? "text-white transition-all duration-300 ease-in-out rounded-lg gap-x-3 bg-regal-blue hover:bg-gradient-to-l hover:from-regal-yellow hover:to-regal-blue"
              : "text-blue-600 border-b-2 border-blue-600"
          }`}
          onClick={() => setActiveTab("pdf")}
        >
          View Pdf
        </button>
        <button
          className={`inline-flex items-center justify-center px-6 py-3 text-xl font-bold leading-6 text-center ${
            activeTab === "video"
              ? "text-white transition-all duration-300 ease-in-out rounded-lg gap-x-3 bg-regal-blue hover:bg-gradient-to-l hover:from-regal-yellow hover:to-regal-blue"
              : "text-blue-600 border-b-2 border-blue-600"
          }`}
          onClick={() => setActiveTab("video")}
        >
          View Video
        </button>
      </div>
      <div className="p-6 bg-white rounded-lg shadow-md">
        {activeTab === "pdf" ? (
          <div className="w-full h-[75vh] md:h-[85vh]">
            {loading ? (
              "Loading..."
            ) : error ? (
              <div>{error}</div>
            ) : pdfUrls.length > 0 ? (
              <>
                <div className="flex items-center justify-between mb-4">
                  <button onClick={handlePrevPdf} className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600">
                    Previous PDF
                  </button>
                  <span className="text-lg font-semibold">
                    PDF {currentPdfIndex + 1} of {pdfUrls.length}
                  </span>
                  <button onClick={handleNextPdf} className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600">
                    Next PDF
                  </button>
                </div>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                  <Viewer fileUrl={pdfUrls[currentPdfIndex]} plugins={[defaultLayoutPluginInstance]}/>
                </Worker>
              </>
            ) : (
              <div>No PDFs available</div>
            )}
          </div>
        ) : (
          <div className="w-full">
            {embedUrl &&
              (embedUrl.includes("youtube.com") ? (
                <iframe className="w-full h-[40vh] md:h-[60vh]" src={embedUrl} frameBorder="0" 
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title="Video player"
                />
              ) : (
                <video controls className="w-full h-[40vh] md:h-[60vh]" src={embedUrl}>
                  Your browser does not support the video tag.
                </video>
              ))}
          </div>
        )}
      </div>
    </div>
  );
}