/* eslint-disable react/prop-types */
export default function TableheadPanel(props) {
    const { openform } = props

    return (
        <div className="flex flex-col md:items-center md:justify-between md:flex-row">
            <div><h2 className="mb-2 text-2xl font-bold md:mb-0">Gallery</h2></div>
            <div className="flex items-center gap-4">
                
                <div>
                    <button onClick={openform} className="flex items-center gap-1 px-4 py-2.5 text-sm  shadow-sm rounded-xl bg-yellow-500">
                        <i className="mt-1 fi fi-rr-square-plus text-md"></i> 
                        <span className="hidden md:block">Add Images</span>
                    </button>
                </div>
            </div>
        </div>
    )
    
}

