/* eslint-disable react/prop-types */

import { Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from '@nextui-org/react';
import { MultiSelect } from 'primereact/multiselect';

export default function Studenttable(props) {

    const { data, sendEmail, loadingEmail, courses,batches, handleCourseChange,handleBatchChange, handleSaveCourses, selectedCourses,selectedBatches, editform, confirm2} = props;
    const formattedCourses = Array.isArray(courses) ? courses : [];
    const formattedBatches = Array.isArray(batches) ? batches : [];

    return (
        <div className="my-5 overflow-auto bg-white border rounded-xl">
            <table className="divide-y divide-gray-200 rounded-xl">
                <thead >
                    <tr>
                        <th scope="col" className="px-6 py-5 text-sm font-medium text-start">S.No.</th>
                        <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Action</th>
                        <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Full Name</th>
                        <th scope="col" className="px-6 py-5 text-sm font-medium text-start">DoB</th>
                        <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Gender</th>
                        <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Address</th>
                        <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Community</th>
                        <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Father Name</th>
                        <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Occupation</th>
                        <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Mobile Number</th>
                        <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Alternative Number</th>
                        <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Email Address</th>
                        <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Educational Qualification</th>
                        <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Name of the college</th>
                        <th scope="col" className="px-6 py-5 text-sm font-medium text-start">How you know</th>
                        <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Preferred Exam</th>
                        <th scope="col" className="px-6 py-5 text-sm font-medium text-start">UserName</th>
                        <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Password</th>
                        <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Application Status</th>
                        <th scope="col" className="px-6 py-5 text-sm font-medium text-start">User Status</th>
                        <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Send Mail</th>
                        <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Add Course</th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                    {data?.resdata?.map((item, index) => (
                        <tr key={index}>
                            <td scope="col" className="px-6 py-5 text-sm text-gray-500">{index + 1}</td>
                            <td scope="col" className="px-6 py-5 text-sm text-gray-500">
                                <Dropdown>
                                <DropdownTrigger>
                                    <i className="text-lg cursor-pointer fi fi-rr-menu-dots-vertical"></i>
                                </DropdownTrigger>
                                <DropdownMenu aria-label="Dynamic Actions" >
                                    <DropdownItem onPress={() => editform(item)} color={"default"}>
                                    Edit
                                    </DropdownItem>
                                    <DropdownItem onPress={() => confirm2(item._id)} color={"danger"} className={"text-danger"}>
                                    Delete
                                    </DropdownItem>
                                </DropdownMenu>
                                </Dropdown>
                            </td>
                            <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.Full_name}</td>
                            <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.Dob}</td>
                            <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.Gender}</td>
                            <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.Address}</td>
                            <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.Community}</td>
                            <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.Father_Name}</td>
                            <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.Occupation}</td>
                            <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.Mobile_Number}</td>
                            <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.Alternative_Number}</td>
                            <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.Email}</td>
                            <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.Educational_Qualification}</td>
                            <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.College_Name}</td>
                            <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.how_you_know}</td>
                            <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.preferred_exam}</td>
                            <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.UserName}</td>
                            <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.password}</td>
                            <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.Application_Status}</td>
                            <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.Status}</td>
                            <td className="px-6 py-5 text-sm text-black ">
                                <button
                                    className={`p-1 rounded-lg bg-[#7828C8] text-white ${loadingEmail === item.Email ? 'opacity-50 cursor-not-allowed' : ''}`}
                                    onClick={() => sendEmail(item.Email, item.UserName, item.password, item.Mobile_Number)}
                                    disabled={loadingEmail === item.Email}
                                >
                                    {loadingEmail === item.Email ? (
                                        <svg className="inline-block w-5 h-5 mr-3 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                                        </svg>
                                    ) : (
                                        <svg className="inline-block w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4 4l16 8-16 8V4z" />
                                        </svg>
                                    )}
                                </button>
                            </td>
                            <td scope="col" className="px-8 text-sm text-gray-500 py-7">
                                <div className='flex flex-col gap-3'>
                                    <MultiSelect
                                        value={selectedCourses[index] || []}
                                        options={formattedCourses}
                                        placeholder="Select Courses"
                                        maxSelectedLabels={3}
                                        className="w-full border border-gray-300 rounded-md p-multiselect"
                                        onChange={(e) => handleCourseChange(e, index)}
                                    />
                                   <MultiSelect
                                        value={selectedBatches[index] || []}
                                        options={formattedBatches}
                                        placeholder="Select Batches"
                                        maxSelectedLabels={3}
                                        className="w-full border border-gray-300 rounded-md p-multiselect"
                                        onChange={(e) => handleBatchChange(e, index)}
                                    />
                                    <button 
                                        className='bg-[#7828C8] p-1 text-white rounded-lg' 
                                        onClick={() => handleSaveCourses(index)}
                                    >
                                        <svg className="inline-block w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                                        </svg>
                                    </button>
                                </div>
                            </td>

                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}