/* eslint-disable react/prop-types */
import { useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button } from "@nextui-org/react";
import apiurl from '../../services/apiendpoint/apiendpoint';

export function CustomTable(props) {
  const { data, editform, confirm2 } = props;
  const [selectedImages, setSelectedImages] = useState([]);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);

  const handleViewImages = (images) => {
    setSelectedImages(images);
    setIsImageModalOpen(true);
  };

  return (
    <>
      <div className="overflow-auto bg-white border rounded-xl h-[400px] xl:h-[500px] 2xl:h-[700px] my-5">
        <table className="min-w-full divide-y divide-gray-200 rounded-xl">
          <thead>
            <tr>
              <th scope="col" className="px-6 py-5 text-sm font-medium text-start">S.no</th>
              <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Title</th>
              <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Status</th>
              <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Action</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {data?.resdata?.map((item, index) => (
              <tr key={index}>
                <td className="px-6 py-5 text-sm text-gray-500">{index + 1}</td>
                <td className="px-6 py-5 text-sm text-gray-500">{item.title}</td>
                <td className="px-6 py-5 text-sm text-gray-500">{item.status}</td>
                <td scope="col" className="flex items-center px-6 py-5 space-x-2 text-sm text-gray-500">
                  <i 
                    onClick={() => handleViewImages(item.images)} 
                    className="text-lg text-gray-600 transition-colors cursor-pointer fi fi-rr-eye hover:text-blue-500"
                  ></i>
                  <Dropdown>
                    <DropdownTrigger>
                      <i className="text-lg cursor-pointer fi fi-rr-menu-dots-vertical"></i>
                    </DropdownTrigger>
                    <DropdownMenu aria-label="Dynamic Actions">
                      <DropdownItem onPress={() => editform(item)} color={"default"}>
                        Edit
                      </DropdownItem>
                      <DropdownItem onPress={() => confirm2(item._id)} color={"danger"} className={"text-danger"}>
                        Delete
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Modal isOpen={isImageModalOpen} onOpenChange={() => setIsImageModalOpen(false)} size="3xl">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Gallery Images</ModalHeader>
              <ModalBody>
                <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-6">
                  {selectedImages.map((image, index) => (
                    <img key={index} src={`${apiurl()}/${image}`} alt={`Gallery Image ${index + 1}`} className="object-cover w-full h-48 rounded-lg" />
                  ))}
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>Close</Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}