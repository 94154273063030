/* eslint-disable react/prop-types */
import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import { FileUpload } from 'primereact/fileupload';
import { Button } from 'primereact/button';
import apiurl from "../../services/apiendpoint/apiendpoint";

export default function AddandEditform (props) {
    const {visible, setVisible, formdata, handlesave, handleupdate, handlechange} = props;
    const [images, setImages] = useState([]);

    useEffect(() => {
        if (formdata?._id && formdata.images) {
            const existingImages = formdata.images.map((img) => ({
                file: null,
                preview: typeof img === 'string' ? `${apiurl()}/${img}` : img.url || img,
                title: typeof img === 'object' ? img.title || '' : '',
            }));
            setImages(existingImages);
        }
    }, [formdata]);
    
    

    const customUploader = (e) => {
        console.log("Files received:", e.files);
    
        if (!e.files || e.files.length === 0) {
            console.warn("No files uploaded.");
            return;
        }
    
        const uploadedFiles = Array.from(e.files);
        const newImages = uploadedFiles.map((file) => ({
            file: file,
            preview: URL.createObjectURL(file),
            title: file.name.split(".")[0],
        }));
    
        setImages((prevImages) => [...prevImages, ...newImages]);
    };
    

    const removeImage = (index) => {
        const newImages = images.filter((_, i) => i !== index);
        setImages(newImages);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        
        const formData = new FormData();
        
        // Add title
        if (formdata.title) {
            formData.append('title', formdata.title);
        }
    
        // Add ID for update
        if (formdata?._id) {
            formData.append('_id', formdata._id);
        }
        
        // Handle images
        const newImageFiles = images.filter(image => image.file);
        const existingImageUrls = images.filter(image => !image.file).map(image => 
            // Ensure we're sending the correct URL format
            image.preview.startsWith('blob:') ? image.preview : 
            image.preview.replace(`${apiurl()}/`, '')
        );
    
        // Append new image files
        newImageFiles.forEach((image) => {
            formData.append('images', image.file);
        });
    
        // Append existing image URLs
        existingImageUrls.forEach(url => {
            formData.append('existingImages', url);
        });
    
        // Differentiate between save and update
        if (!formdata?._id) {
            handlesave(formData);
        } else {
            handleupdate(formData);
        }
    
        setVisible(false);
    };

    return(
        <Dialog 
            header={formdata?._id ? "Edit Gallery" : "Add Gallery"} 
            visible={visible} 
            onHide={() => setVisible(false)} 
            style={{ width: "50vw" }} 
            breakpoints={{ "960px": "75vw", "641px": "100vw" }} 
            maximizable
        >
            <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-1 gap-y-2">
                    <div>
                        <label>Gallery Title</label>
                        <input 
                            type="text" 
                            name="title" 
                            className="w-full px-4 py-2 mt-1 border outline-none rounded-xl" 
                            onChange={handlechange} 
                            value={formdata.title || ''}
                            required 
                        />
                    </div>
                    <div>
                        <label>Upload Images</label>
                        <FileUpload
                            name="images"
                            multiple
                            accept="image/*"
                            customUpload
                            uploadHandler={customUploader}
                            chooseLabel="Select Images"
                        />


                        <div className="grid grid-cols-2 gap-4 mt-4 md:grid-cols-3 lg:grid-cols-6">
                            {images.map((image, index) => (
                                <div key={index} className="relative p-2 border rounded-lg">
                                    <img 
                                        src={image.preview} 
                                        alt={`Preview ${index}`} 
                                        className="object-cover w-full h-40 rounded-lg"
                                    />
                                    
                                    <button 
                                        type="button"
                                        onClick={() => removeImage(index)}
                                        className="absolute p-1 text-white bg-red-500 rounded-full top-2 right-2"
                                    >
                                        ✕
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                    <Button 
                        type="submit" 
                        label={formdata?._id ? "Update Gallery" : "Create Gallery"} 
                        className="p-2 px-5 mt-4 text-white w-fit bg-secondary"
                    />
                </div>
            </form>
        </Dialog>
    )
}